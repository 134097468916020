.body {}

.emptyImage {
    width: 50%;
    height: 40vh;
    margin: auto;
    padding-bottom: 10vh;
}

.emptyImage img {
    margin-top: 5vh;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.body h4 {
    width: 50%;
    border-radius: 20px;
    padding: 5px;
    text-align: center;
    background: #6ca856;
    margin: auto;
    color: white;
}