.body {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.body img {
    width: 50%;
    height: 1em;
    filter: invert(100%);
    fill: deepskyblue;
}