.body {
    padding: 8px;
}

.body input {
    text-align: center;
    width: 95%;
    height: 100%;
    background: white;
    border-radius: 50px;
    border: none;
    outline: none;
    font-size: large;
}

@-moz-document url-prefix() {
    .body input {
        width: -moz-available;
    }
}