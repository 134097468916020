.body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 50%;
    height: 90%;
    border-radius: 20px;
    background: deepskyblue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}