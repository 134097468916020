.body {
    width: 80%;
    border-radius: 20px;
    padding: 8px;
    background: deepskyblue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 auto;
}