.body {
    padding: 8px;
}

.body div {
    height: 100%;
    text-align: center;
    background: lightgreen;
    border-radius: 10px;
}

.body h4 {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

