.body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0,0,0,50%);
    z-index: 3;
    overflow: hidden;
}