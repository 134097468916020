.body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 90%;
    height: 90%;
    position: relative;
    border-radius: 20px;
    object-fit: contain;
    background-size: cover;
}

.bg {
    width: 100%;
    height: 50%;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,5,32,0.5) 24%, rgba(0,212,255,0) 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
}