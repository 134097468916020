.body {
    width: 100%;
    height: 10%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    color: white;
    border-radius: 20px;
    width: 50%;
    background: deepskyblue;
}