.body {
    display: flex;
    overflow-x: auto;
    height: 40%;
    border-radius: 10px;
}

.body img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 10px 0 0;
}