.body {
    min-width: 15%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body h4 {
    border: deepskyblue 2px solid;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: deepskyblue;
}

.body span {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: white;
    background: deepskyblue;
}

