.body {
    position: fixed;
    top: 7%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.panel {
    min-width: 30%;
    max-width: 50%;
    height: 7vh;
    background: white;
    border-radius: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: slide-bottom .5s cubic-bezier(.25, .46, .45, .94) both;
    position: relative;
}

.panel h4 {
    position: absolute;
    left: 0;
    margin: 0;
    width: 100%;
    text-align: center;
}

.panel img {
    height: 90%;
    width: 20%;
    object-fit: contain;
}


@keyframes slide-bottom {
    0% {
        transform: translateY(-7vh);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    }
    100% {
        transform: translateY(0);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
}

