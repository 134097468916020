.body {
    position: absolute;
    bottom: 0;
    height: 10%;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    width: 100% ;
}

@media (max-width: 900px) {
    .body {
        position: absolute;
        bottom: 10px;
    }
}