.body {
   width: 100%;
   height: 30%;
}

.body img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 20px;
}