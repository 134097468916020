.body {
    min-height: 7%;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #eaeaea;
    border-radius: 10px;
}

.body h4 {
    width: 100%;
    position: center;
    margin: 0;
}

.body span {
    margin-left: 5px;
    text-align: start;
    width: 100%;
}

.body img {
    width: 10%;
    object-fit: contain;
}

.body input {
    border-radius: 10px;
    margin-left: 5px;
    border: none;
    outline: none;
    width: 90%;
    height: 70%;
    padding-left: 5px;
}