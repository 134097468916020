.body {
    background: #cdcdcd;
    padding: 8px;
    border-radius: 10px;
    margin:  0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body input {
    width: 100%;
    outline: none;
    border-radius: 20px;
    border: none;
    text-align: center;
}

.body input:invalid {
    outline: 1px red;
    border: 1px red;
}

.paste {
    width: 10vw;
    background-size: contain;
    color: white;
    background: #d9d8d8 center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 5px;
}