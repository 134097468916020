.body {
    background: linear-gradient(29deg, rgba(2,0,36,1) 0%, rgba(5,5,32,1) 77%, rgba(200,0,255,1) 100%);
    border-radius: 20px;
    width: 90%;
    height: 80%;
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding-left: 10px;
}

.off {
    background: linear-gradient(29deg, rgba(2,0,36,0.2245448521205357) 0%, rgba(5,5,32,0.2758053563222164) 77%, rgba(200,0,255,0.2749650201877626) 100%);;
}