.body {
    height: 20%;
    background: #6ca856;
    border-radius: 20px;
    padding: 5px;
}
.body h4 {
    margin: auto;
}
.body span {

}

.buttons {
    height: 30%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.buttons img {
    width: 40%;
    height: 100%;
    object-fit: contain;
    background: #eaeaea;
    border-radius: 10px;
}