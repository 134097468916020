.body {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
}

.imgBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.video {
    object-fit: cover;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
}

.panel {
    width: 60vw;
    background-color: white;
    display: flex;
    height: 60vh;
    border-radius: 20px;
}

@media (max-width: 700px) {
    .panel {
        width: 100%;
        border-radius: 20px 20px 0 0;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60vh;
        z-index: 1;
    }

    .video {
        height: 50%;
        z-index: 1;
    }

    .body {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .imgBanner {
        height: 50%;
    }
}