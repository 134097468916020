.body {
    background-color: #e0aeae;
    border-radius: 20px 20px 0 0 ;
    width: 100vw;
    max-width: 600px;
    height: 30%;
    text-align: center;
}

.body h4 {
    margin: 0;
    width: 100%;
    background-color: red;
    border-radius: 20px 20px 0 0 ;
}
.errorBlock {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.errorBlock .lottie {
    width: 40%;
}
.errorBlock span {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyIP {
    background-color: #e0aeae;
    border-radius: 20px 20px 0 0 ;
    width: 100vw;
    max-width: 600px;
    height: 30%;
    text-align: center;
    position: absolute;
    bottom: 0;
    margin: auto;
}

.title {
    margin: 0;
    width: 100%;
    background-color: red;
    border-radius: 20px 20px 0 0 ;
}

.bgButton {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.bodyIP span {
    margin-top: 15px;
    font-family: "Book Antiqua";
    font-size: large;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy {
    margin: auto;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: red;
    border-radius: 30px;
}