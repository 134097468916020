.body {
    width: calc(60vw * 0.1);
    height: calc(60vw * 0.1);
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 100%;
    background: deepskyblue ;
}

.body img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .body {
        width: calc(60vh * 0.12);
        height: calc(60vh * 0.12);
        z-index: 1;
        bottom: 5vh;
        right: 5vh;
    }

    .body img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}