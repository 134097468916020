.body {
    width: calc(100% - 10px);
    height: 10%;
    display: flex;
    background: darkgray;
    margin: 5px;
    border-radius: 10px;
}

.body span {
    width: 90%;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toggle {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
}

.toggleOn {
    justify-content: flex-end;
}

.toggleOff {
    justify-content: end;
}

.togButOff {
    background: #cdcdcd;
    width: 50%;
    height: 90%;
    border-radius: 100%;
}

.togButON {
    background: #5dff00;
    width: 50%;
    height: 90%;
    border-radius: 100%;
}