.body {
    height: 100%;
    padding: 8px;
    overflow-y: scroll;
}

.body::-webkit-scrollbar {
    width: 5px;/* ширина scrollbar */
}
.body::-webkit-scrollbar-track {
    background: white;
    /* цвет дорожки */
}
.body::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 5px solid white;  /* padding вокруг плашки */
}