.body {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    height: 40%;
}

.empty {
    width: 950px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.body img {
    width: 300px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 10px 0 0;
}

.body::-webkit-scrollbar {
    height: 5px;/* ширина scrollbar */
}
.body::-webkit-scrollbar-track {
    background: white;
    /* цвет дорожки */
}
.body::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 5px solid white;  /* padding вокруг плашки */
}