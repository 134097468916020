.body {
    background-color: white;
    border-radius: 20px 20px 0 0 ;
    width: 100vw;
    max-width: 600px;
    height: 50%;
    text-align: center;
    padding: 8px 0 8px 8px;
    overflow-y: scroll;
}

.body::-webkit-scrollbar {
    width: 8px;/* ширина scrollbar */
}
.body::-webkit-scrollbar-track {
    /* цвет дорожки */
    background: transparent;
}
.body::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 5px solid white;  /* padding вокруг плашки */
}