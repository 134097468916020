.body {
    text-align: center;
    padding: 8px;
}

.body h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    background-color: deepskyblue;
    border-radius: 10px;
    outline: none;
}

.nav {
    color: white;
    text-decoration: none;
}

.navActiv h4 {
    color: deepskyblue;
    background: #cdcdcd;
    border: deepskyblue solid 5px;
}

@media (max-width: 900px) {
    .body {
        padding: 0;
        width: calc(100%/5.1);
    }

    .body h4 {
        font-size: calc(100%/1.2);
    }
}