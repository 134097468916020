.body {
    height: 10%;
    margin-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    width: 85%;
    height: 100%;
}
.input input {
    width: 90%;
    height: 100%;
    background: dimgrey;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-left: 5%;
    padding-left: 5px;
}

.go {
    width: 15%;
    height: 100%;
}
.go img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}